body,html,.app,#root {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
}
.center-group {
    animation: hero-text 600ms ease-out both;
}
.center-group span{ /* The main typewriter title */
    font-size: 1.5em;
    font-family: commuters_sansbold, sans-serif;
    letter-spacing: 5px;
}
.center-group h1 {
    line-height: 1.2;
    margin: calc(0.25em + 1vw) 0 calc(0.25em + 2vw);
}
.center-group h2 {
    font-size: 1.2em;
    line-height: 1.7;
    letter-spacing: 2px;
}
.center-group h3 {
    cursor: pointer;
}
.center-group a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.center-group p {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1.5px white;
    font-size: 2.5em !important;
}
.footer-wrapper:before {
    display: none;
}
.full-group {
    padding-top: calc(var(--spacing) + 4rem);
    flex: 1;
    /*--hero-link-underline: var(--c-bD);*/
    display: flex;
    align-items: flex-end;
    padding: var(--spacing) 0;
    position: relative;
    z-index: 3;
    align-items: center;
    background: var(--primary);
}
.center-group {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    max-width: 900px;
    width: 92%;
    color: white;
}
footer {
    padding-top: 0;
}