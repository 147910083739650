.cards {
    margin-top: -2rem;
    background: var(--background);
  }

  .cards-container {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  
  .cards-wrapper {
    position: relative;
  }
  
  .cards-item {
    position: relative;
    display: flex;
    margin-bottom: 2rem;
    border-radius: 10px;
    background-color: var(--card-background);
    z-index: 0;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  }

  .cards-item-img {
    margin-left: 20px;
    margin-top: 15px;
    width: 25%;
    height: 90%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .cards-item-container {
    padding: 40px;
    display: flex;
  }

  .cards-item-text {
    flex:1;
  }

  .cards-item-text p {
    color: var(--text-primary);
    font-family: 'commuters_sanslight',sans-serif;
    line-height: 1.8;
  }
  
  .cards-item-title {
    color: #252e48;
    font-family: commuters_sansbold, sans-serif;
    font-size: 2em;
    line-height: 1.5;
    padding-bottom: 5px;
    background: #08AEEA;
    background: -webkit-linear-gradient(to top, #08AEEA 15%, #2AF598 85%);
    background: -moz-linear-gradient(to top, #08AEEA 15%, #2AF598 85%);
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @media only screen and (min-width: 1200px) {
    .content-blog-container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards-items {
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards-item {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 550px) {
    .cards-item-img {
      display: none;
    }
  }
  