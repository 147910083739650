.navbar {
    background: var(--card-background);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    z-index: 9;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  }

.skip-nav-link {
  position: absolute;
  background: var(--buttonprimary);
  padding: 0.5rem 1.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  left: 0.5rem;
  transform:translateY(-120%);
  transition: transform 325ms ease-in-out;
  color: var(--text-primary);
}

.skip-nav-link:focus {
  transform: translateY(0);
  position:absolute;
  z-index: 99999999;
}
  
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-width: 1500px;
}

.navbar-logo {
  color: var(--text-primary);
  justify-self: start;
  margin: 0 1.5rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
}
.navbar-logo svg {
  width: 50px;
  height: auto;
}
.navbar-logo path {
  fill: var(--text-primary);
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
  margin-right: 2rem;
}
  
.nav-item {
  height: 80px;
}

.nav-links {
  color: var(--accent);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--text-primary);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.mobile-only {
  display: none;
}

  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 70px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: end;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 999999;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 10px;
      left: 0;
      /*transform: translate(25%, 20%);*/
    }

    .mobile-only {
      display: block;
    }

    .desktop-only {
      display: none;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 50%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: var(--text-primary);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }