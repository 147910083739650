.hero-container {
    position: relative;
    overflow: hidden;
    padding: 0 calc(37vw - 10rem);
    height: calc(3rem + 25vw);
    min-height: 450px;
    max-height: 550px;
    background: var(--primary);
}

.hero-container-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    justify-content: center;
    height: 100%;
}

.hero-items {
    color: white;
    margin: 0 0%;
    padding-bottom: 50px;
    animation: opacity 400ms ease-out both;
}

.hero-items img {
    padding-top: 50px;
    width: 30vw;
    max-width:300px;
    min-width: 200px;
}

.stroke-text {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1.5px white;
    font-size: 2em !important;
}

.hero-text h1 {
    font-size: 4em;
    font-family: commuters_sansbold, sans-serif;
    letter-spacing: 10px;
}
.hero-text p {
    font-size: 1.2em;
    line-height: 1.7;
}

.hero-image {
    position: absolute;
    bottom: 50;
    height: 100%;
    min-width: 140px;
    transform-origin: center center;
}

.hero-image img  {
    /*width: 20vw;
    max-width: 200px;*/
    height: 100%;
}

.image-left {
    left: -15px;
}

.image-right {
    right: -15px
}

.hero-text {
    z-index:1;
    animation: hero-text 600ms ease-out both;
}
@keyframes hero-text {
    0% {
        opacity: 0;
        transform: translateY(-0.5em);
    }
    100% {
        transform: none;
        opacity: 1;
    }
}
@keyframes opacity {
    0% {
        opacity: 0;
    }
}



@media (max-width: 55em) {
    .person-image  {
        display: none;
    }
    .hero-text {
        text-align: center;
    }
}



@media (max-width: 45em) {
    .hero-container {
        min-height: 400px;
    }
    .image-right {
        display: none;
    }
    .image-left {
        width: 100%;
        left: 0;
    }
    .hero-image img {
        height: auto;
        width: 100%;
        position: absolute;
        bottom: 0px;
    }
    .hero-items h1 {
        font-size: 3.5em;
    }
    .hero-text {
        padding: 6rem 1rem 38% 1rem;
    }
}