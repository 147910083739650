.button-group {
    margin-top: 5px;
}

.btn--outline--two {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    color: var(--text-primary);
    background: var(--button-background);
    background-clip: padding-box; /* !important */
    border: solid 3px transparent; /* !important */
    transition: all 0.2s ease-out;
    border-radius: 1em;
  }
.btn--outline--two:before {
    cursor: pointer;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px; /* !important */
    border-radius: inherit; /* !important */
    background: var(--buttonprimary);
    transition: all 0.2s ease-out;
    
  }
  .btn--outline--two:hover {
    background: transparent;
    color: #fff;
    transition: all 0.2s ease-out;
}

.btn {
    padding: 5px 15px;
    margin: 10px 10px 10px 0;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    font-family: 'commuters_sanslight',sans-serif;
}

.btn--primary {
    background: var(--primary);
    color: #fff;
    border: 1px solid #fff;
}

.btn--small {
    padding: 5px 15px;
    font-size: 0.8em;
}

.btn--medium {
    padding: 7px 18px;
    font-size: 1em;
}

.btn--large {
    padding: 12px 26px;
    font-size: 1.2em;
}

.btn--outline:hover {
    background: var(--primary);
    border: 3px solid var(--accent);
    color: #fff;
    transition: all 0.1s ease-out;
}

.svg-inline--fa {
    margin-right: 7px;
}

