footer {
    color: var(--text-primary);
    display: grid;
    margin-top: auto;
    padding-top: var(--spacing);
    font-size: 0.8em;
    animation: opacity 400ms 400ms ease-out both;
    background: var(--card-background);
}

.footer-angle {
    animation: scale-from-bottom 600ms 0ms ease-out both;
    height: 2vw;
    width: 100%;
    transform-origin: center top;
    transform: rotate(180deg);
    /* animation: scale-from-top 600ms 0ms ease-out both; */
    display: block;
    width: 100%;
    color: var(--card-background);
    z-index: 99;
}

.footer-angle path {
    fill: currentColor;
}

@keyframes scale-from-bottom {
    0% {
        transform: scale(1, 0) rotate(180deg);
    }
    
    100% {
        transform: scale(1,1) rotate(180deg);
    }
}

.footer-wrapper {
    grid-template-columns: 8fr 3fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 8.33333%;
    display: grid;
    /*grid-gap: calc(var(--spacing) / 2) 0;*/
    padding: var(--spacing) 0;
    padding-top: 2em !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    width: 92%;
    margin: 0 auto;
}
.footer-wrapper:before {
    background: grey;
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    height: 2px;
    border-radius: 1px;
}

.footer-description {
    max-width: 46em;
    margin-bottom: calc(var(--spacing) / 2);
}
.footer-links {
    grid-column: 1;
    display: flex;
    flex-wrap: wrap;
}

.footer-links .name {
    box-sizing: border-box;
    min-width: 12.5%;
    margin-right: 0;
    padding-right: 1em;
}

.footer-links a {
    text-decoration: none;
    color: var(--text-primary);
}

.footer-links .name a {
    color: var(--accent);
    text-transform: uppercase;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 1em;
}
.footer-tools {
    grid-column: 2;
    grid-row: 1 / span 2;
    display: block;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 9.09091%;
    flex-wrap: wrap;
}
.footer-line {
    box-sizing: border-box;
    display: flex;
    margin-right: 0;
    align-items: center;
    margin-bottom: 1.5rem;
}
.footer-line label {
    font-weight: 600;
    color: var(--accent);
    min-width: 33.33333%;
}
.select-input {
    flex: 1;
    min-width: 50%;
    display: inline-block;
    position: relative;
}
.select-input svg {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    margin-top: -0.05rem;
    width: 0.25rem;
    height: 0.25rem;
    color: var(--text-primary);
}
.select-input path {
    fill: currentColor;
}
.theme-select {
    width: 100%;
    font-variant-caps: all-small-caps;
    font-size: 1.05em;
    line-height: 1;
    background: none;
    color: var(--text-primary);
    display: block;
    appearance: none;
    border: 0;
    border-radius: 0;
    /*outline: 0;*/
    border-bottom: 2px solid var(--accent);
    padding: 0.25rem 1rem 0.25em 0;
    transition: border-color 125ms ease-in-out;
    font-size: 1rem;
    font-weight: 500;
}
@media screen and (max-width: 700px){
    .footer-tools {
        grid-column: 1;
        grid-row:unset;
        margin-top:1em;
    }
    .footer-line {
        margin-bottom: 1em;
    }
    .footer-line label {
        min-width: 25%;
    }
    .footer-wrapper {
        display: block;
    }
    .footer-links {
        margin: 2.5em 0;
    }
}