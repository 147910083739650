* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: commuters_sansregular, sans-serif;
  }

html {
  scroll-behavior: smooth;
}

:root { /* variables that are consistent no matter what colour scheme */
  --spacing: calc(1rem + 2vw);
}

[data-theme-preference="teal"] {
  --accent: #03c2bb;
   /*--primary: linear-gradient(90deg, rgb(35, 221, 137) 20%, rgb(8,174,234) 80%); OLD, BEFORE I USED 'PRETTY GRADIENTS'*/
   --primary: linear-gradient(90deg,
   hsl(153deg 73% 50%) 8%,
   hsl(163deg 100% 43%) 32%,
   hsl(169deg 100% 42%) 41%,
   hsl(175deg 100% 41%) 47%,
   hsl(181deg 100% 41%) 53%,
   hsl(185deg 100% 43%) 57%,
   hsl(189deg 100% 45%) 62%,
   hsl(192deg 100% 46%) 68%,
   hsl(194deg 100% 46%) 75%,
   hsl(196deg 93% 47%) 95%);
   /* old --buttonprimary: linear-gradient(to left, #08AEEA -10%, #23dd89 110%);*/
   --buttonprimary: linear-gradient( 90deg,
   hsl(153deg 73% 50%) 0%,
   hsl(163deg 100% 43%) 11%,
   hsl(169deg 100% 42%) 22%,
   hsl(175deg 100% 41%) 33%,
   hsl(181deg 100% 41%) 44%,
   hsl(185deg 100% 43%) 56%,
   hsl(189deg 100% 45%) 67%,
   hsl(192deg 100% 46%) 78%,
   hsl(194deg 100% 46%) 89%,
   hsl(196deg 93% 47%) 100%
   );
   /*--gradient-text: linear-gradient(to top, #08AEEA 15%, #2AF598 85%);*/
   --gradient-text: linear-gradient(180deg,
   rgb(42, 255, 159) 10%,
   hsl(163deg 100% 43%) 40%,
   hsl(169deg 100% 42%) 44%,
   hsl(175deg 100% 41%) 56%,
   hsl(181deg 100% 41%) 64%,
   hsl(185deg 100% 43%) 70%,
   hsl(189deg 100% 45%) 76%,
   hsl(192deg 100% 46%) 85%,
   hsl(194deg 100% 46%) 90%,
   hsl(196deg 93% 47%) 100%);
}
[data-theme-preference="sunrise"] {
  --primary: linear-gradient(90deg, #FF4E50 20%, #F9D423 80%);
  --buttonprimary: linear-gradient(to right, #FF4E50 -10%, #F9D423 110%);
  --gradient-text: linear-gradient(to top, #FF4E50 15%, #F9D423 85%);
  --accent:#f09753;
}
[data-theme-preference="purple"] {
  --primary: linear-gradient(90deg, #5926d8 20%, #8e68e6 80%);
  --buttonprimary: linear-gradient(to right, #5926d8 -10%, #8e68e6 110%);
  --gradient-text: linear-gradient(to top, #5926d8 15%, #8e68e6 85%);
  --accent:#986fff;
}
[data-theme-preference="pink/blue"] {
  --primary: linear-gradient(90deg, #b6019b 20%, #2aabe4 80%);
  --buttonprimary: linear-gradient(to right, #b6019b -10%, #2aabe4 110%);
  --gradient-text: linear-gradient(to top, #b6019b 15%, #2aabe4 85%);
  --accent:#8577ff;
}

[data-color-preference="auto"] { /*default to light mode if user doesn't support colour schemes*/
  --text-primary: #747474;
  --background: #ecececec;
  --card-background: #fff;
  --button-background: #fff;
  --white-background: #fff;
}
@media (prefers-color-scheme: dark) {
  [data-color-preference="auto"] { /*if auto is selected on the <select>, and OS scheme is dark, use dark variables*/
    --background: #1c181e;
    --white-background: #1c181e;
    --text-primary: white;
    --text-secondary: grey;
    --card-background: #494949;
    --button-background: none;
  }
}

[data-color-preference="light"] { /* if light selected on the <select>, use light variables */
  --text-primary: #747474;
  --background: #ecececec;
  --card-background: #fff;
  --button-background: #fff;
  --white-background: #fff;
}

[data-color-preference="dark"] { /* if dark selected on the <select>, use dark variables */
  --background: #1c181e;
  --text-primary: white;
  --text-secondary: grey;
  --card-background: #494949;
  --button-background: none;
  --white-background: #1c181e;
}

.app {
  background: var(--card-background);
}

.external-link {
  text-decoration: underline;
  color: inherit;
}

.external-link::after {
  content: '↗';
  color: var(--accent);
  font-weight: 1000;
  position: relative;
  top: -0.4em;
  font-style: normal;
  letter-spacing: -0.15em;
  margin-left: 0.05em;
  margin-right: 0.2em;
  font-size: 0.825em;
  text-decoration: none;
  text-decoration-color: var(--none);
  display: inline-block
}

  @font-face {
    font-family: 'commuters_sansbold';
    src: url('components/fonts/commuterssans-bold-webfont.woff2') format('woff2'),
         url('components/fonts/commuterssans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'commuters_sansregular';
    src: url('components/fonts/commuterssans-regular-webfont.woff2') format('woff2'),
         url('components/fonts/commuterssans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'commuters_sanslight';
    src: url('components/fonts/commuterssans-light-webfont.woff2') format('woff2'),
         url('components/fonts/commuterssans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /*background-image: url('/images/img-2.jpg');*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    /*background-image: url('/images/img-1.jpg');*/
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /*background-image: url('/images/img-8.jpg');*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }